.ideal-logo {
  justify-content: center;
  display: flex;
  background-color: transparent;
  position: fixed;
  top: 22px;
  left: 0;
  right: 0;
  z-index: 7;
  mix-blend-mode: difference !important;

  align-items: center;
  height: 30px;
  align-items: flex-start;
  overflow: hidden;
}
.ideal-logo img {
  padding-bottom: 42px;
  width: 80px;
  color: #fff;
  filter: invert(1);
  cursor: pointer;
}

.ideal-logo:hover .effect {
  transform: translatey(-60px);
  transition: 0.2s ease-in-out;
}
.ideal-logo:hover .origin {
  transform: translatey(-60px);
  transition: 0.2s ease-in-out;
}
