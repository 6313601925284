@font-face {
  font-family: "Pretendard";
  font-weight: bold;
  src: url("../../font/Pretendard-Bold.otf") format("opentype");
}
body {
  font-family: "Pretendard";
  font-weight: bold;
}
.photos {
  cursor: pointer;
}
.slider-container {
  z-index: 500;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.Brandinfo2 {
  // position: absolute;

  /* headline1 */

  // font-family: "Pretendard";
  font-weight: bold;
  // font-style: normal;
  // font-weight: bold;
  // font-size: 177px;
  // line-height: 212px;

  // border: 2px solid #000000;

  position: relative;
  width: 100%;
  // height: 12500px;
  left: 0px;
  top: 0px;
  overflow: hidden;

  background: linear-gradient(180deg, #fff0db 0%, #fff0db 85.42%, #c9dbe0 88.54%, #c8dae0 100%);
}

#ideal_showroom__main {
  z-index: 5;

  position: absolute;
  top: 40vh;
  left: 100px;
  font-size: 9vw;
  mix-blend-mode: difference;
  color: #fff;
  font-family: "Pretendard";
  font-weight: 700;
  line-height: 1;
  width: 80%;
}

#Brandinfo2_upper_text {
  position: fixed;
  top: 50vh;
  left: 100px;
  font-size: 9vw;
  transform: translate(0, -50%);
  // animation: changeMainText ;
  animation: changeMainText 1000ms linear forwards;

  mix-blend-mode: difference;
  /* headline1 */
  // -webkit-text-stroke-color: #fff;
  // color: #fff;
  -webkit-text-stroke-color: #fff;
  // -webkit-text-stroke-width: ;
  -webkit-text-stroke-width: 0.1vw;
  color: transparent;
  font-family: "Pretendard";
  font-weight: bold;
  font-style: normal;
  font-weight: bold;
  font-size: 9vw;
  z-index: 5;
  // animation: movingTopToBottom 1s linear infinite;
  line-height: 1;
  width: 80%;

  // -webkit-animation: movingTopToBottom 10s linear infinite;
  // transform: translateX(-50%) translateY(-50%);
  // position: absolute;

  // border: 2px solid #000000;
}

@keyframes changeMainText {
  0% {
    color: #fff;
  }
  50% {
    color: #fff;
  }
  100% {
    /* headline1 */
    // -webkit-text-stroke-color: #fff;
    -webkit-text-stroke-width: 0.1vw;
    color: transparent;
  }
}

@keyframes movingTopToBottom {
  from {
    position: fixed;
    opacity: 1;
    // transform: translateY(100%);
  }
  to {
    position: fixed;
    // opacity: 0;
  }
}

#Brandinfo2_pic_1 {
  position: relative;
  display: flex;
  margin-top: 9.42vw;
  left: 32.08vw;
  //   bottom: 94.07%;
}

#Brandinfo2_pic_2 {
  position: relative;
  display: flex;
  margin-top: -4.84vw;
  left: 8.38vw;
  //   bottom: 85.73%;
}

#Brandinfo2_pic_3 {
  position: relative;
  display: flex;
  margin-top: 11.66vw;
  left: 43.75vw;
  //   bottom: 80.38%;
}

#Brandinfo2_pic_4 {
  position: relative;
  display: flex;
  margin-top: 9.79vw;
  justify-content: flex-end;
  //   bottom: 69.61%;
}

#Brandinfo2_pic_5 {
  position: relative;
  display: flex;
  margin-top: 24.84vw;
  //   bottom: 58.4%;
}

#Brandinfo2_pic_6 {
  position: relative;
  display: flex;
  margin-top: 17.76vw;
  left: 53.12vw;
  //   bottom: 49.32%;
}

#Brandinfo2_pic_7 {
  position: relative;
  display: flex;
  margin-top: 9.94vw;
  left: 13.48vw;
  //   bottom: 45.91%;
}

#Brandinfo2_pic_8 {
  position: relative;
  display: flex;
  margin-top: -5.67vw;
  left: 25.15vw;
  //   bottom: 40.48%;
}

#Brandinfo2_pic_9 {
  position: relative;
  display: flex;
  margin-top: 24.32vw;
  left: 5.88vw;
  //   bottom: 33.29%;
}

#Brandinfo2_pic_10 {
  position: relative;
  display: flex;
  margin-top: -9.47vw;
  left: 50vw;
  //   bottom: 25.12%;
}

#Brandinfo2_pic_11 {
  position: relative;
  display: flex;
  margin-top: 23.48vw;
  //   bottom: 14.1%;
}

#Brandinfo2_pic_12 {
  position: relative;
  display: flex;
  margin-top: -5.41vw;
  justify-content: flex-end;
  //   bottom: 10.5%;
}

// #Brandinfo2_pic_13 {
//   position: absolute;
//   left: 19.22%;
//   right: 10.62%;
//   top: 80%;

//   //   bottom: 1.63%;
// }

// #Brandinfo2_pic_14 {
//   position: absolute;
//   left: 38.49%;
//   right: 7.81%;
//   top: 87.91%;
// }

// #Brandinfo2_pic_15 {
//   position: absolute;
//   left: 13.33%;
//   right: 51.3%;
//   top: 90.61%;
// }

// #Brandinfo2_pic_16 {
//   position: absolute;
//   left: 58.59%;
//   right: 13.02%;
//   top: 94.14%;
//   //   bottom: 6.16%;
// }

#Brandinfo2_bottom_text {
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  left: 85px;
  top: 89.5%;
  z-index: 1;
  /* headline1 */

  font-family: "Pretendard";
  font-weight: bold;
  font-style: normal;
  font-weight: bold;
  font-size: 125px;
  line-height: 135px;
  mix-blend-mode: difference;
  color: #fff;
}

#BrandInfo2_back {
  transform: rotatez(90deg);
  z-index: 2;
  position: relative;
  left: 94%;
  top: 55px;
  width: 65px;
  height: 29px;
  cursor: pointer;

  /* Subtitle2 */

  font-family: "Pretendard";
  font-weight: bold;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
}
.cursor_overlay {
  cursor: none;
}

@media (max-width: 767px) {
  .Brandinfo2 {
    // height: 5000px;
  }
  #Brandinfo2_upper_text,
  #Brandinfo2_bottom_text {
    top: 50vh !important;
    left: 22px;
    font-size: 12vw;
    -webkit-text-stroke-color: #fff;
    // -webkit-text-stroke-width: ;
    -webkit-text-stroke-width: 0.4vw;
  }

  @keyframes changeMainText {
    0% {
      color: #fff;
    }
    50% {
      color: #fff;
    }
    100% {
      /* headline1 */
      // -webkit-text-stroke-color: #fff;
      -webkit-text-stroke-width: 0.4vw;
      color: transparent;
    }
  }
}

.brand-img-1 {
  //   bottom: 94.07%;
  width: 59.29% !important;
  aspect-ratio: 1100/720;
  height: 37.5vw;
  object-fit: cover;
}

.brand-img-2 {
  width: 50% !important;
  aspect-ratio: 960/1300;
  height: 67.7vw;
  object-fit: cover;
}

.brand-img-3 {
  width: 47.91% !important;
  aspect-ratio: 920/670;
  height: 34.89vw;
  object-fit: cover;
}

.brand-img-4 {
  width: 59.89% !important;
  aspect-ratio: 1150/1450;
  height: 75.52vw;
  object-fit: cover;
}

.brand-img-5 {
  width: 100% !important;
  aspect-ratio: 1920/1240;
  height: 64.58vw;
  object-fit: cover;
}

.brand-img-6 {
  width: 38.54% !important;
  aspect-ratio: 740/1050;
  height: 54.68vw;
  object-fit: cover;
}

.brand-img-7 {
  width: 28.12% !important;
  aspect-ratio: 540/340;
  height: 17.7vw;
  object-fit: cover;
}

.brand-img-8 {
  width: 70.83% !important;
  aspect-ratio: 1360/940;
  height: 48.95vw;
  object-fit: cover;
}

.brand-img-9 {
  width: 52.08% !important;
  aspect-ratio: 1000/760;
  height: 39.58vw;
  object-fit: cover;
}

.brand-img-10 {
  width: 41.66% !important;
  aspect-ratio: 800/1200;
  height: 62.5vw;
  object-fit: cover;
}

.brand-img-11 {
  width: 78.12% !important;
  aspect-ratio: 1500/1200;
  height: 62.5vw;
  object-fit: cover;
}

.brand-img-12 {
  width: 48.43% !important;
  aspect-ratio: 930/670;
  height: 34.89vw;
  object-fit: cover;
}
