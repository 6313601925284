@font-face {
  font-family: "Pretendard";
  src: url("../../font//Pretendard-Bold.otf") format("opentype");
}

.noHover {
  transform: none !important;
}

.backButton {
  padding: 5px;
  position: fixed;
  width: 120px;
  height: 36px;
  top: 12%;
  right: 1.4%;
  transform: rotate(90deg);
  overflow: hidden;
  cursor: pointer;
  // z-index: 2;
  mix-blend-mode: difference;
  color: #fff;
  z-index: 100;

  @media (max-width: 767px) {
    top: 8% !important;
    right: -6% !important;
  }
}
.effect {
  transform: translatey(0);
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.origin {
  transform: translatey(0);
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.backButton:hover .effect {
  transform: translatey(-48px);
  transition: 0.2s ease-in-out;
}
.backButton:hover .origin {
  transform: translatey(-48px);
  transition: 0.2s ease-in-out;
}

.backButton button {
  border: none;
  // background: transparent;
  background-color: transparent;
  font-family: "Pretendard";
  font-weight: bold;
  font-size: 26px;
  @media (max-width: 767px) {
    font-size: 20px;
  }
}

//  .homepage_button {
//   border: none;
//   background-color: white;
//   font-family: 'Pretendard';
//   font-weight: bold;
//   font-size:24px;
//   transform: rotate(90deg);
//   cursor: pointer;
// }

.homepage_header {
  justify-content: space-between !important;
  padding-top: 5%;
}

.homepage_header button {
  border: none;
  background-color: white;
  font-family: "Pretendard";
  font-weight: bold;
  font-size: 26px;
  color: #000000;
  transform: rotate(90deg);
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease;
}

// .homepage_header .brand_button {
//   align-items: flex-end;
//   text-align: left;
//   width: 70px;
//   height: 75px;
//   mix-blend-mode: difference;
//   background: transparent;
//   color: #fff;
// }

// .homepage_header .about_button {
//   float: right;
//   align-items: flex-start;
//   height: 80px;
//   mix-blend-mode: difference;
//   background: transparent;
//   color: #fff;
// }

.top_effect {
  // transform: translatey(0);
  // transition: 0.2s ease-in-out;
  // cursor: pointer;
  float: right;
}

// .top_origin {
//   transform: translatey(0);
//   transition: 0.2s ease-in-out;
//   cursor: pointer;
// }

.homepage_header .top_effect:hover {
  transform: translatey(-48px);
  transition: 0.2s ease-in-out;
}
.homepage_header .top_origin:hover {
  transform: translatey(-48px);
  transition: 0.2s ease-in-out;
}

.aboutButton {
  padding: 5px;
  position: fixed;
  width: 120px;
  height: 36px;
  top: 12%;
  right: 1.4%;
  transform: rotate(90deg);
  overflow: hidden;
  cursor: pointer;
  z-index: 5;
  mix-blend-mode: difference;
  // -webkit-text-stroke-color: slategrey;
  @media (max-width: 767px) {
    top: 8% !important;
    right: -6% !important;
  }
}
.about_effect {
  transform: translatey(0);
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
}
.about_origin {
  transform: translatey(0);
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
}
.aboutButton:hover .about_effect {
  transform: translatey(-48px);
  transition: 0.2s ease-in-out;
}
.aboutButton:hover .about_origin {
  transform: translatey(-48px);
  transition: 0.2s ease-in-out;
}
.aboutButton button {
  border: none;
  // background-color: white;
  font-family: "Pretendard";
  font-weight: bold;
  font-size: 26px;
  // color: #000000;
  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.brandButton {
  padding: 5px;
  position: fixed;
  width: 120px;
  height: 36px;
  top: 12%;
  left: 40px;
  transform: rotate(90deg);
  overflow: hidden;
  cursor: pointer;
  z-index: 5;
  mix-blend-mode: difference;
  @media (max-width: 767px) {
    top: 8% !important;
    left: -20px !important;
  }
}
.brand_effect {
  transform: translatey(0);
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
}
.brand_origin {
  transform: translatey(0);
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
}
.brandButton:hover .brand_effect {
  transform: translatey(-48px);
  transition: 0.2s ease-in-out;
}
.brandButton:hover .brand_origin {
  transform: translatey(-48px);
  transition: 0.2s ease-in-out;
}
.brandButton button {
  border: none;
  font-family: "Pretendard";
  font-weight: bold;
  font-size: 26px;
  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.calendarButton {
  padding: 5px;
  position: fixed;
  width: 148px;
  height: 36px;
  bottom: 12%;
  right: 1%;
  transform: rotate(90deg);
  overflow: hidden;
  cursor: pointer;
  z-index: 5;
  mix-blend-mode: difference;
  @media (max-width: 767px) {
    bottom: 8% !important;
    right: -9.6% !important;
  }
}
.calendar_effect {
  transform: translatey(0);
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
}
.calendar_origin {
  transform: translatey(0);
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
}
.calendarButton:hover .calendar_effect {
  transform: translatey(-48px);
  transition: 0.2s ease-in-out;
}
.calendarButton:hover .calendar_origin {
  transform: translatey(-48px);
  transition: 0.2s ease-in-out;
}
.calendarButton button {
  border: none;
  font-family: "Pretendard";
  font-weight: bold;
  font-size: 26px;
  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.digitalButton {
  position: fixed;
  width: 400px;
  height: 36px;
  left: -112px;
  bottom: 106px;
  transform: rotate(90deg);
  overflow: hidden;
  cursor: pointer;
  z-index: 5;
  mix-blend-mode: difference;
  @media (max-width: 767px) {
    bottom: 3.8% !important;
  }
}
.digital_effect {
  transform: translatey(0);
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
}
.digital_origin {
  transform: translatey(0);
  transition: 0.2s ease-in-out;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
}
.digitalButton:hover .digital_effect {
  transform: translatey(-48px);
  transition: 0.2s ease-in-out;
}
.digitalButton:hover .digital_origin {
  transform: translatey(-48px);
  transition: 0.2s ease-in-out;
}
.digitalButton button {
  border: none;
  font-family: "Pretendard";
  font-weight: bold;
  font-size: 26px;
  @media (max-width: 767px) {
    font-size: 20px;
  }
}

@media (max-width: 1024px) {
  // .backButton button {
  //   font-size: 26px;
  // }
}

@media (max-width: 768px) {
  // .backButton button {
  //   top: 5%;
  //   font-size: 18px;
  // }
  .homepage_header {
    padding-top: 12%;
  }
} //tab

@media (max-width: 545px) {
  // .backButton button {
  //   top: 5%;
  //   font-size: 18px;
  // }
  .homepage_header {
    padding-top: 15%;
  }
} // for mobile
