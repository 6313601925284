// Import
// Login
@import "../src/Component/authentication/Login/Login";
@import "../src/Component/authentication/Login Complete/LoginCompleted";

// Signup
@import "../src/Component/authentication/Signup/Signup";
@import "../src/Component/authentication/Signup Complete/SignupCompleted";

// Calendar
@import "../src/Component/Calendar/Calendar";

// Reset
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Pretendard";
}

li {
  list-style-type: none;
}
body::-webkit-scrollbar {
  display: none;
}

// Fonts
@font-face {
  font-family: "Pretendard";
  src: url("./font/Pretendard-Thin.otf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "Pretendard";
  src: url("./font/Pretendard-ExtraLight.otf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Pretendard";
  src: url("./font/Pretendard-Light.otf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Pretendard";
  src: url("./font/Pretendard-Regular.otf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Pretendard";
  src: url("./font/Pretendard-Medium.otf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Pretendard";
  src: url("./font/Pretendard-SemiBold.otf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Pretendard";
  src: url("./font/Pretendard-Bold.otf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Pretendard";
  src: url("./font/Pretendard-ExtraBold.otf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Pretendard";
  src: url("./font/Pretendard-Black.otf") format("truetype");
  font-weight: 900;
}

$mainFont: "Pretendard";

// Colors
$mainColor: #000000;
$textColor: #ffffff;

.ril__outer {
  background-color: #e0e0e0 !important;
}

.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}
