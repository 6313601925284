.main-logo__fade-in {
  height: "0px";
  //   animation: fadein 1s;
  //   -moz-animation: fadein 1s; /* Firefox */
  //   -webkit-animation: fadein 1s; /* Safari and Chrome */
  //   -o-animation: fadein 1s; /* Opera */

  display: inline;
  z-index: 100;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.main-logo__fade-out {
  height: "0px";
  //   animation: fadeout 1s;
  //   -moz-animation: fadeout 1s; /* Firefox */
  //   -webkit-animation: fadeout 1s; /* Safari and Chrome */
  //   -o-animation: fadeout 1s; /* Opera */
  //   animation-fill-mode: forwards;
  display: none;

  z-index: 100;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeout {
  /* Firefox */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeout {
  /* Safari and Chrome */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fadeout {
  /* Opera */
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
