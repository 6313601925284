@font-face {
  font-family: "Pretendard";
  src: url("../../font/PretendardVariable.ttf") format("truetype");
}
.back {
}

.container {
  height: 100vh;
}

.container .backButton {
  color: black;
  // -webkit-text-stroke-width: 4px;
}
.Images {
  height: 100vh;
  position: relative;
  display: flex;
  place-items: center;
  img {
    position: absolute;
    height: auto;
    width: 15vw;
    visibility: hidden;
  }
}

#wrap {
  position: absolute !important;
  height: 50vw;
  width: 50vw;
  border-radius: 50%;
  top: 50% !important;
  transform: translate(-80%, -50%);
}
svg {
  position: absolute !important;
  top: 50%;
  left: 50%;
  height: 100%;
  overflow: visible;
}

#path {
  fill: none;
}

.brands {
  font-family: "Pretendard";
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  .brandsText {
    font-size: 4.5vw;
    line-height: 101px;
    width: 50vw;
    transform: rotate(-90deg);

    .brandName {
      text-decoration: none;
      color: #f6c544 !important;

      .brandsIndex {
        font-weight: 900;
        font-size: 1.5vw;
        line-height: 41px;
        margin-left: 14px;
        letter-spacing: -3px;
      }
    }
    .brandName__hover {
      color: black !important;

      &:hover {
        color: white !important;
        text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black,
          1px 1px 0 black !important;
      }
    }
  }

  .brandsTextWhite {
    font-size: 4.5vw;
    line-height: 101px;
    width: 50vw;
    transform: rotate(-90deg);

    .brandName {
      text-decoration: none;
      color: #44d2ff !important;

      .brandsIndex {
        font-size: 2vw;
        line-height: 41px;
      }
    }
    .brandNameWhite__hover {
      color: white !important;
      &:hover {
        color: black !important;
        text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white,
          1px 1px 0 white !important;
      }
    }
  }
}

@keyframes moveToRight {
  0% {
    transform: scaleX(0) translateX(0vw);
    transform-origin: 0 50%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1) translateX(20vw);
    opacity: 1;
    transform-origin: 0 50%;
  }
}

@keyframes fade {
  0% {
    transform: translateX(300px);
    opacity: 1;
    transition-delay: 0.5s;
  }
  100% {
    transform: translateX(600px);
    opacity: 0;
    transition-delay: 0.5s;
  }
}

@media screen and (max-width: 1024px) {
  .brands {
    img {
      height: 300px;
      width: 200px;
    }
    .brandsText {
      line-height: 101px;
      font-size: 6vw;
      width: 100vw;
      height: 100px;
      transform: rotate(-90deg);
      .brandsIndex {
        font-size: 34px;
        line-height: 41px;
      }
    }
  }
  #wrap {
    height: 100vw;
    width: 100vw;
    padding-top: 93%;
    border-radius: 50%;
    transform: translate(-85%, -50%);
    transform-origin: center;

    transition: all 0.1s linear forwards;
  }
}

@media screen and (max-width: 768px) {
  .brands {
    img {
      height: 200px;
      width: 150px;
    }
    .brandsText {
      height: 50px;
      line-height: 43px;
      font-size: 6vw;
      width: 100vw;
      transform: rotate(-95deg);

      .brandsIndex {
        font-size: 20px;
        line-height: 25px;
      }
    }
    .brandsTextWhite {
      height: 50px;
      line-height: 43px;
      font-size: 6vw;
      width: 100vw;
      transform: rotate(-95deg);

      .brandsIndex {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
  #wrap {
    height: 100vw;
    width: 100vw;
    padding-top: 75%;
    border-radius: 50%;
    transform: translate(-90%, -50%);
  }
  // #wrap{height:600px; width:600px; transform: translate(-90%, -50%); }
}

@media screen and (max-width: 545px) {
  .brands {
    .brandsText {
      height: 50px;
      line-height: 43px;
      font-size: 6vw;
      width: 100vw;
      transform: rotate(-100deg);

      .brandsIndex {
        font-size: 15px;
        line-height: 18px;
      }
    }
    .brandsTextWhite {
      height: 50px;
      line-height: 43px;
      font-size: 6vw;
      width: 100vw;
      transform: rotate(-100deg);

      .brandsIndex {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
  #wrap {
    height: 100vw;
    width: 100vw;
    padding-top: 100%;
    border-radius: 50%;
    // transform: translate(-110%, -50%);
  }
  // #wrap{height:400px; width:400px; transform: translate(-100%, -50%); }
}
