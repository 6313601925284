@font-face {
  font-family: "Pretendard";
  font-weight: bold;
  src: url("../../font/Pretendard-Bold.otf") format("opentype");
}
body {
  font-family: "Pretendard";
  font-weight: bold;
}
.slider-container {
  z-index: 500;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.BrandInfo3 {
  /* headline1 */

  // font-family: "Pretendard";
  font-weight: bold;
  // font-style: normal;
  // font-weight: bold;
  // font-size: 177px;
  // line-height: 212px;

  //   border: 2px solid #000000;
  overflow: hidden;
  // height: 12500px;

  position: relative;
  width: 100%;
  //   height: 17502px;
  left: 0px;
  top: 0px;

  background: linear-gradient(180deg, #c8dae0 0%, #c8dae0 86.46%, #dae0c8 88.54%, #dae0c8 100%);
}

#BrandInfo3_upper_text {
  position: absolute;
  width: 920px;
  height: 240px;
  left: 85px;
  top: 246px;

  /* headline1 */
  -webkit-text-stroke-color: slategrey;
  -webkit-text-stroke-width: 2px;
  color: transparent;
  font-family: "Pretendard";
  font-weight: bold;
  font-style: normal;
  font-weight: bold;
  font-size: 127px;
  line-height: 120px;
  z-index: 2;
  position: fixed;
  //   border: 2px solid #000000;
}

#BrandInfo3_pic_1 {
  position: relative;
  display: flex;
  // margin-top: -5.67vw;
  left: 19.21vw;
}

#BrandInfo3_pic_2 {
  position: relative;
  display: flex;
  margin-top: -55.93vw;
  left: 54.84vw;
}

#BrandInfo3_pic_3 {
  position: relative;
  display: flex;
  margin-top: 36.1vw;
  justify-content: flex-end;
  // left: 25.15vw;
}

#BrandInfo3_pic_4 {
  position: relative;
  display: flex;
  margin-top: -14.06vw;
  left: 8.8vw;
}

#BrandInfo3_pic_5 {
  position: relative;
  display: flex;
  margin-top: 33.54vw;
}

#BrandInfo3_pic_6 {
  position: relative;
  display: flex;
  margin-top: -10.57vw;
  left: 50vw;
}

#BrandInfo3_pic_7 {
  position: relative;
  display: flex;
  margin-top: 29.42vw;
  left: 8.95vw;
}

#BrandInfo3_pic_8 {
  position: relative;
  display: flex;
  margin-top: 12.7vw;
  justify-content: flex-end;
}

#BrandInfo3_pic_9 {
  position: relative;
  display: flex;
  margin-top: 13.17vw;
  justify-content: flex-end;
}

#BrandInfo3_pic_10 {
  position: relative;
  display: flex;
  margin-top: 16.92vw;
  left: 32.39vw;
}

#BrandInfo3_pic_11 {
  position: relative;
  display: flex;
  margin-top: 17.44vw;
  // left: 25.15vw;
}

#BrandInfo3_pic_12 {
  position: relative;
  display: flex;
  margin-top: 27.18vw;
  justify-content: flex-end;
}

// #BrandInfo3_pic_13 {
//   position: relative;
//   display: flex;
//   margin-top: -5.67vw;
//   left: 25.15vw;
// }

// #BrandInfo3_pic_14 {
//   position: relative;
//   display: flex;
//   margin-top: -5.67vw;
//   left: 25.15vw;
// }

// #BrandInfo3_pic_15 {
//   position: relative;
//   display: flex;
//   margin-top: -5.67vw;
//   left: 25.15vw;
// }

// #BrandInfo3_pic_16 {
//   position: relative;
//   display: flex;
//   margin-top: -5.67vw;
//   left: 25.15vw;
// }

// #BrandInfo3_pic_17 {
//   position: relative;
//   display: flex;
//   margin-top: -5.67vw;
//   left: 25.15vw;
// }

#BrandInfo3_bottom_text {
  cursor: pointer;
  position: absolute;
  width: 920px;
  height: 240px;
  left: 85px;
  top: 89.5%;
  z-index: 1;
  /* headline1 */

  font-family: "Pretendard";
  font-weight: bold;
  font-style: normal;
  font-weight: bold;
  font-size: 127px;
  line-height: 135px;
}

#BrandInfo3_back {
  transform: rotatez(90deg);
  z-index: 2;
  position: relative;
  left: 94%;
  top: 55px;
  width: 65px;
  height: 29px;
  cursor: pointer;

  /* Subtitle2 */

  font-family: "Pretendard";
  font-weight: bold;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
}
.photos {
  cursor: pointer;
}
.cursor_overlay {
  cursor: none;
}
@media (max-width: 767px) {
  .BrandInfo3 {
    // height: 5000px;
  }
  #BrandInfo3_upper_text,
  #BrandInfo3_bottom_text {
    font-size: 12vw;
    line-height: 1.4;
    left: 15px;
  }
}

.brand-img3-1 {
  //   bottom: 94.07%;
  width: 40.62% !important;
  aspect-ratio: 780/1300;
  height: 67.7vw;
  object-fit: cover;
}

.brand-img3-2 {
  width: 29.68% !important;
  aspect-ratio: 570/560;
  height: 29.16vw;
  object-fit: cover;
}

.brand-img3-3 {
  width: 73.95% !important;
  aspect-ratio: 1420/900;
  height: 46.87vw;
  object-fit: cover;
}

.brand-img3-4 {
  width: 17.7% !important;
  aspect-ratio: 340/540;
  height: 28.12vw;
  object-fit: cover;
}

.brand-img3-5 {
  width: 100% !important;
  aspect-ratio: 1920/1240;
  height: 64.58vw;
  object-fit: cover;
}

.brand-img3-6 {
  width: 39.06% !important;
  aspect-ratio: 750/1050;
  height: 54.68vw;
  object-fit: cover;
}

.brand-img3-7 {
  width: 70.31% !important;
  aspect-ratio: 1350/960;
  height: 50vw;
  object-fit: cover;
}

.brand-img3-8 {
  width: 38.54% !important;
  aspect-ratio: 1;
  height: 38.54vw;
  object-fit: cover;
}

.brand-img3-9 {
  width: 80.72% !important;
  aspect-ratio: 1550/1200;
  object-fit: cover;
}

.brand-img3-10 {
  width: 46.87% !important;
  aspect-ratio: 900/1100;
  height: 57.29vw;
  object-fit: cover;
}

.brand-img3-11 {
  width: 100% !important;
  aspect-ratio: 1920/1500;
  height: 78.12vw;
  object-fit: cover;
}

.brand-img3-12 {
  width: 35.41% !important;
  aspect-ratio: 680/1000;
  height: 52.08vw;
  object-fit: cover;
}
