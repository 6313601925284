// @import '~antd/dist/antd.css';
@font-face {
  font-family: "Pretendard";
  src: url("../../font//PretendardVariable.ttf") format("truetype");
}

.Header {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  font-family: "Pretendard";
  font-style: normal;
  font-size: 14px !important;
  font-weight: 600;
  .FirstRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    border-bottom: 1px solid black;
    .Logo {
      float: left;
      display: flex;
      place-items: center;
      justify-content: center;
      padding: 0 64px;
      img {
        height: 22px;
      }
    }
    .BrandsFilter {
      float: left;
      display: flex;
      place-items: center;
      .ButtonDD {
        border: none;
        box-shadow: none;
        width: max-content;
        height: max-content;
        font-size: 13px !important;
        font-weight: 700;
        text-decoration: none;
        color: black;
        i {
          margin-left: 5px;
          padding-top: 0px;
          line-height: 1;
        }
      }
      .ButtonDD:hover {
        color: black;
        border: none;
        outline: none;
      }
    }

    .RightDiv {
      float: right;
      display: flex;
      place-items: center;
      justify-content: center;
      margin-right: 48px;
      a {
        text-decoration: none;
        margin: auto 10px;
        color: black;
        font-weight: 700;
      }
    }
  }
  .Nevigation {
    position: fixed;
    top: 52px;
    left: 0;
    right: 0;
    background-color: white;
    height: 52px;
    border-bottom: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    padding-left: calc(6vw + 40px);
    a {
      text-decoration: none;
      font-weight: 700;
      color: black;
      padding: 8px 15px;
    }
    i {
      padding: 12px 0px;
      color: black;
      font-size: 10px;
    }
  }
}
.ThirdRow {
  margin-top: 104px;
  height: 52px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  padding-left: calc(6vw + 40px);
  border-bottom: 1px solid black;
  a {
    text-decoration: none;
    color: black;
    padding: 8px 15px;
    font-weight: 400;
  }
  i {
    padding: 12px 0px;
    color: black;
    font-size: 10px;
  }
  span {
    padding: 8px 0px;
    color: #828282;
  }
}
.VideoContainer {
  display: flex;
  justify-content: center;
  border: none;
  position: relative;

  background-size: cover;
  .BrandText {
    position: absolute;
    top: 70%;
    font-family: Cochin;
    font-style: normal;
    font-weight: normal;
    font-size: 5vw !important;
    color: white;
    text-transform: uppercase;
  }
}
.ImageContainer {
  // height: 85vh;
  display: flex;
  justify-content: center;
  border: none;

  background-size: cover;
  .BrandText {
    position: absolute;
    top: 85%;
    margin-top: -100px;
    font-family: Cochin;
    font-style: normal;
    font-weight: normal;
    font-size: 5vw !important;
    color: white;
    text-transform: uppercase;
  }
}

.ModelCutList {
  .wrapper {
    border-top: 1px solid black;
    width: 100%;
    height: 50px;
    position: relative;
    margin: auto;
    overflow: hidden;
  }
  .ModelName {
    height: 50px;
    position: absolute;
    margin: 0 30px;
    font-size: 15px;
    line-height: 50px;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
  }
  .ModelNames {
    position: relative;
    left: -350px; //same as box width
  }

  .productModelImages {
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    border-bottom: 1px solid black;
    // border-left: 1px solid black;
  }

  .productModelImages__hover {
    transition-duration: 0.7s;

    &:hover {
      @media (min-width: 767px) {
        -webkit-filter: blur(15px);
        filter: blur(15px);
      }

      opacity: 0.8;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-top: -1px;
    // border: 1px solid black;
    // border-right: 1px solid black;
    // border-left: 1px solid black;
    border-left: none;
    outline: hidden;
  }
  .productImages {
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    border-left: 1px solid black;
  }
  .ProductCut {
    max-height: 60vh;
    background: #e0e0e0;
    margin-top: -2px;
    border: 1px solid black;
    border-left: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ProductCutImage {
      display: flex;
      width: 100%;
      height: 360px;
      overflow: hidden;
      border: none;
      img {
        width: 50%;
        border: none !important;
      }
    }
    .ProductCutText {
      font-weight: normal;
      font-size: 13px;
      text-align: center;
      margin-top: 24px;
      margin-bottom: 40px;
      color: black;
      li:nth-child(1) {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 12px;
      }
      li:nth-child(4) {
        font-size: 15px;
        font-weight: bold;
        margin-top: 12px;
      }
    }
  }
}
.ModelDetailsPage {
  background: #e5e5e5;
  display: grid;
  justify-content: center;
  position: relative;
  .ModelImages {
    .arrows {
      position: fixed;
      top: 50vh;
      left: 6vw;
      right: 6vw;
      z-index: 100;

      display: flex;
      .arrow {
        color: grey;
        font-size: 50px;
        svg {
          top: 0;
          left: 0;
        }
      }
      .left {
        position: absolute;
        top: 0;
        left: 8px;
        width: 50px;
      }
      .right {
        position: absolute;
        top: 0;
        right: 8px;
        width: 50px;
      }
    }

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 34.89vw;
      aspect-ratio: 670/900;
      object-fit: cover;
    }
  }
  .ProductImages {
    display: grid;
    place-items: center;
    width: 1500px;
    .ProductImage {
      display: flex;
      width: 100%;
      height: 80vh;
      img {
        display: flex;
        width: 50%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.ril__zoomOutButton,
.ril__zoomInButton {
  display: none;
}
.ril__nextButton,
.ril__prevButton {
  width: 100px !important;
  height: 100px !important;
}

.ril__toolbar {
  background: none !important;
}
.ril__closeButton {
  height: 40px !important;
  border-radius: 100px !important;
  border: 1px solid white !important;
}
.ril__image {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
  transform: translate3d(0px, 0px, 0px) !important;
}
.ril__imagePrev {
  display: none;
}
.ril__imageNext {
  display: none;
}
.Footer {
  height: auto;
  width: 100vw;
  font-family: "Pretendard";
  bottom: 0px;

  .FooterButton {
    padding: 50px;
    display: grid;
    place-items: center;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(150px, 280px));
    button {
      background: #ffffff;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 100px;
      height: 44px;
      margin: 10px 0;
      width: 260px;
      span {
        text-transform: uppercase;
        font-size: 15px !important;
        font-weight: 300;
        letter-spacing: 0.1em;
      }
    }
    button:hover {
      cursor: pointer;
    }
  }

  .FooterOptions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    border-left: 1px solid black;
    background-color: #f6f6f6;
    .Block {
      height: 50vh;
      border: 1px solid black;
      border-left: none;
      margin-top: -1px;
      .BlockCategory {
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        border-bottom: 1px solid black;
        text-align: center;
        text-transform: uppercase;
        p {
          margin-bottom: 0px;
        }
      }
      .BlockBrandNames {
        font-weight: normal;
        font-size: 14px !important;
        text-align: center;
        padding-top: 55px;
      }
    }
  }
}

.ScrollToTopBtn {
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  color: black;
  cursor: pointer;
  font-size: 28px;
  width: 64px;
  height: 64px;
  position: fixed;
  bottom: 48px;
  right: 48px;
  z-index: 100;
}

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@media screen and (max-width: 1500px) {
  .ProductImages {
    width: 100vw !important;
    .ProductImage {
      height: 50vh !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .Header {
    font-size: 2.5vw !important;
    .FirstRow {
      .Logo {
        padding-left: 15px;
        img {
          width: 14vw;
        }
      }
      .BrandsFilter {
        .ButtonDD {
          font-size: 2.5vw !important;
        }
      }
      .RightDiv {
      }
    }
    .Nevigation {
      a {
        padding: 8px 15px;
      }
      i {
        padding: 15px 0px;
      }
      span {
        padding: 15px 0px;
      }
    }
  }
  .ThirdRow {
    a {
      padding: 8px 15px;
    }
  }
  .VideoContainer {
    .BrandText {
      top: 70%;
      font-size: 8vw !important;
    }
  }
  .ImageContainer {
    height: 90vh;
    .BrandText {
      top: 90%;
      font-size: 8vw !important;
    }
  }
  .ModelCutList {
    .productModelImages {
      // grid-template-columns: 1fr 1fr 1fr;
      .ProductCut {
        min-height: 40vh;
      }
      .ProductCutImage {
        img {
          height: 20vh;
        }
      }
    }
    .productImages {
      grid-template-columns: 1fr 1fr;
    }
  }

  .ModelDetailsPage {
    .ModelImages {
      .arrow {
        font-size: 20px;
        svg {
          top: 0;
          left: 0;
        }
      }
    }
    .ProductImages {
      width: 100vw;
      .ProductImage {
        height: 50vh !important;
      }
    }
  }

  .Footer {
    .FooterButton {
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
      button {
        height: 50px;
        width: 350px;
      }
    }
    .FooterOptions {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(345px, 1fr));
      .Block {
        height: 40vh;
        .BlockCategory {
          padding-top: 5px;
        }
        .BlockBrandNames {
          padding-top: 30px;
        }
      }
    }
  }
}

.showroomMainImg {
  width: 100%;
  aspect-ratio: 1920/1080;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .ModelCutList {
    .productModelImages {
      // grid-template-columns: 1fr 1fr;
    }
  }
  .Footer {
    .FooterButton {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      button {
        height: 50px;
        width: 300px;
      }
    }
  }
  .ModelDetailsPage {
    .ModelImages {
      img {
        width: 100%;
      }
    }
  }
  .ProductImages {
    width: 100vw;
    .ProductImage {
      height: 30vh !important;
    }
  }
}

@media screen and (max-width: 545px) {
  .Header {
    font-size: 3vw !important;
    .FirstRow {
      .Logo {
        padding-left: 10px;
        padding-right: 10px;
        img {
          width: 14vw;
        }
      }
      .BrandsFilter {
        .ButtonDD {
          font-size: 3vw !important;
        }
      }
      .RightDiv {
        a {
          margin: 8px;
        }
      }
    }
    .Nevigation {
      a {
        padding: 8px 10px;
      }
      i {
        padding: 12px 0px;
      }
      span {
        padding: 12px 0px;
      }
    }
  }
  .ThirdRow {
    a {
      padding: 8px 10px;
    }
  }
  .VideoContainer {
    .BrandText {
      top: 70%;
      font-size: 8vw !important;
    }
  }
  .ImageContainer {
    // height: 90vh;
    background-position: center;
    .BrandText {
      top: 90%;
      font-size: 8vw !important;
    }
  }
  .ModelCutList {
    .productModelImages {
      grid-template-columns: 1fr;
      border: none;
      .ProductCut {
        min-height: 50vh;

        .ProductCutText {
          font-size: 13px;

          li:nth-child(1) {
            font-size: 21px;
          }
          li:nth-child(4) {
            font-size: 19px;
            font-weight: normal;
          }
        }
      }
      img {
        border: none;
      }
    }
    .productImages {
      grid-template-columns: 1fr;
      border: none;

      .ProductCut {
        // border: none;
        border: 1px solid white !important;
      }
    }
  }

  .ModelDetailsPage {
    .ModelImages {
      .arrow {
        display: none;
      }
      img {
        width: 100%;
      }
    }
    .ProductImages {
      width: 100vw;
      .ProductImage {
        height: 30vh !important;
      }
    }
  }

  .Footer {
    .FooterButton {
      padding: 25px;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      button {
        height: 30px;
        width: 150px;
        span {
          font-size: 10px !important;
        }
      }
      button:hover {
        cursor: pointer;
      }
    }
    .FooterOptions {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      .Block {
        height: 300px;
        .BlockCategory {
          font-size: 14px;
          padding-top: 5px;
        }
        .BlockBrandNames {
          font-size: 12px !important;
          padding-top: 30px;
        }
      }
    }
  }
  .ScrollToTopBtn {
    display: none;
  }
}

.cursor {
  cursor: pointer;
}

.closeButton {
  cursor: pointer;
  margin-top: 16px;
  transition: 0.5s ease-in-out;
  transform-origin: center;
  &:hover {
    transform: rotate(270deg);
  }
}

.ril__closeButton {
  display: none !important;
}

.ant-dropdown-menu {
  div {
    font-size: 11px !important;
    color: #747474;
  }
  li {
    font-size: 11px !important;
    color: #747474;
  }
}
