@font-face {
  font-family: Pretendard;
  src: url("../../../font/PretendardVariable.ttf") format("truetype");
}
.signup {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(67, 67, 67, 1) 100%);
  display: grid;
  place-items: center;
  font-family: "Pretendard";
  font-style: normal;
  color: #ffffff;
}

.signup__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 240px;
}

.signup__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40px;
  margin-bottom: 12px;
  .signup__subheading {
    font-weight: 800;
    font-size: 19px;
    line-height: 35px;
    letter-spacing: 0.25em;
    margin: 12px 0 36px;
    text-align: center;
  }
}

// Signup Form--------------------------------------------------
.signup__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .signup__error {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #828282;
    border-radius: 12px;
    padding: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    margin-bottom: 12px;
  }
  .signup__input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    label {
      font-size: 13px;
      line-height: 16px;
      font-weight: 400;
    }
    input,
    select,
    .signup__select-brands {
      width: 240px;
      background: transparent;
      border: 1px solid white;
      outline: none;
      padding: 7px 10px;
      font-family: inherit;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      color: white;
      line-height: 18px;
      margin-bottom: 20px;
      &::placeholder {
        color: #828282;
      }
    }
    select {
      appearance: none;
      background-image: url("../../../assets/select-arrow.svg");
      background-repeat: no-repeat, repeat;
      background-position: right 0.7em top 50%, 0 0;
      option {
        color: #000000;
      }
      margin-bottom: 0;
    }
  }
  button {
    width: 100%;
    border: none;
    font-family: inherit;
    font-style: normal;
    font-size: 15px;
    line-height: 20px;
    padding: 7px;
    margin: 24px 0 16px;
    cursor: pointer;
    background-color: black;
    color: white !important;
  }
}
// Signup Form--------------------------------------------------

// Please select brands clickable div--------------------------------------------------
.signup__select-brands {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0 !important;
}
// Please select brands clickable div--------------------------------------------------

// Options wrapper--------------------------------------------------
.signup__options-wrapper {
  width: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #000000;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    margin: 4px 0;
  }
}
// Options wrapper--------------------------------------------------

.signup__options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 160px;
  margin: 4px 0;
  margin-right: 20px;
  input {
    width: 12px;
    height: 12px;
    width: 20px !important;
    margin: 0 !important;
    :checked {
      margin-left: 25px;
      border: 1px solid blue;
    }
  }
  label {
    flex: 2;
    text-align: center;
    margin-bottom: 0 !important;
  }
}
