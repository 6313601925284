.login-completed {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(67, 67, 67, 1) 100%);
  display: grid;
  place-items: center;
  font-style: normal;
  color: #ffffff;
}

.login-completed__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 400px;
  .login-completed__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    .login-completed__subheading {
      font-weight: 800;
      font-size: 19px;
      line-height: 35px;
      letter-spacing: 0.25em;
      margin: 12px 0 0 0;
    }
  }
  span {
    font-style: normal;
    font-weight: 300;
    font-size: 23px;
    line-height: 29px;
    text-align: center;
    margin: 66px 0 84px;
  }
}
