// ------------- css for about page --------------

@font-face {
  font-family: "Pretendard";
  font-weight: bold;
  src: url("../../font/Pretendard-Bold.otf") format("opentype");
}
body {
  font-family: "Pretendard";
  font-weight: bold;
}

.h1-text {
  width: 962px;
  left: 80px;
  margin-top: 25px;
  font-family: "Pretendard";
  font-weight: bold;
  font-style: normal;
  font-size: 100px;
  line-height: 106px;
  color: black;
}

.h2-text {
  width: 400px;
  left: 80px;
  font-family: "Pretendard";
  font-weight: bold;
  font-style: normal;
  font-size: 60px;
  line-height: 60px;
  color: black;

  margin-top: 64px;
}

@media (max-width: 767px) {
  .About_mainpage h1 {
    font-size: 33px !important;
    line-height: 45px;
  }
  .to_top_button {
    text-align: center;
  }
  .to_top_button button {
    left: 0 !important;
  }
  .About_mainpage #gmail_tag {
    font-size: 30px !important;
    font-family: "Pretendard";
    font-weight: bold;
  }

  .sc-dlfnuX.eEYWgC {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .kDiHdu {
    top: 20% !important;
    left: 0 !important;
    position: relative !important;
    padding: 20px !important;
  }
  .kgghCh,
  .gqcBVa,
  .gYCnOK {
    width: 100% !important;
    position: relative !important;
    left: 0 !important;
    top: 0px !important;
    padding: 10px !important;
  }
  .cdHENe {
    width: 100% !important;
    padding: 10px !important;
    position: relative !important;
    left: 0.5% !important;
    top: -5px !important;
  }
  .hRIrpv {
    position: relative !important;
    right: 0 !important;
    top: 65% !important;
    padding: 20px !important;
    text-align: center !important;
    text-align: justify !important;
  }
  .bhaKoo {
    text-align: left !important;
    padding: 0 !important;
    display: list-item !important;
    line-height: 1.6 !important;
  }
  .kSVQrv {
    margin: 20px 0 !important;
  }
  .gafRL {
    display: block !important;
    left: 0 !important;
  }
  .sc-iqHYmW.gafRL ul {
    margin: 40px 0 !important;
  }
  .iaTmbV {
    display: flex !important;
    justify-content: center !important;
  }

  .gDWwQX {
    text-align: left !important;
    padding: 0 !important;
    display: list-item !important;
    line-height: 1.6 !important;
  }
  .hRIrpv {
    position: relative !important;
    right: 0 !important;
    top: 65% !important;
    padding: 20px !important;
    text-align: center !important;
    text-align: justify !important;
  }
  .kSVQrv {
    margin: 20px 0 !important;
  }
  .gafRL {
    display: block !important;
    left: 0 !important;
  }
  .sc-iqHYmW.gafRL ul {
    margin: 40px 0 !important;
  }
  .dlugLv {
    display: flex !important;
    justify-content: center !important;
  }
  h1.sc-hBEYId.dukdHK {
    font-size: 33px !important;
    text-align: center;
  }
  h1.sc-gsTEea {
    font-size: 23px !important;
  }
}

// mainpage---------------------------

.contents {
  display: inline-block;
  margin: 20px 0px 0px 0px;
  margin-bottom: 100px;
}

#contents_links {
  margin: 20px;
  padding: 10px;
  display: table-row;
  font-size: 44px;
  line-height: 92px;
  text-decoration: none;
  color: black;
  font-weight: 500;
  font-family: "Pretendard";
  font-weight: bold;
  cursor: pointer;
}

.About_mainpage {
  margin: 80px;
  overflow: hidden;
  font-family: Pretendard;
}

.About_mainpage h1 {
  font-weight: 800;
  font-size: 50px;
}

.About_mainpage #gmail_tag {
  font-weight: 800;
  font-size: 50px;
  color: black;
  text-decoration: none;
}

.About_mainpage .backButton {
  color: black;
}

#contact_links {
  margin: 20px 0px 20px 0px;
  cursor: pointer;
}

#top {
  border-radius: 10px;
  margin: 20px;
  padding: 10px;
  color: white;
  background-color: black;
  position: relative;
  left: 92%;
  bottom: 0px;
}
.footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100px;
  left: 0px;
  margin-bottom: 24px;
  padding-left: 48px;
  padding-right: 48px;
}

.footer__bottomCaption {
  color: gray;
  margin-left: 48px;
  padding-bottom: 12px;
}

.footer .right-align {
  display: flex;
}
.right-align > div {
  flex: auto;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.6);
}
.right-align > div > p {
  margin-bottom: 3px;
}
.right-align > div > p > a {
  color: rgba(0, 0, 0, 0.6);
}
.right-align img {
  margin-right: 10px;
}
.right-align > div:not(:last-child) {
  margin-right: 40px;
}
.right-align > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .h1-text,
  .contents_links_container a {
    width: auto;
    left: 15px;
    margin-top: 25px;
    font-size: 7vw !important;
    line-height: 1.5 !important;
  }
  .contents_links_container div {
    width: auto;
    left: 15px;
    margin-top: 25px;
    font-size: 7vw !important;
    line-height: 1.5 !important;
  }
  .footer .right-align {
    flex-direction: column;
  }
  .footer {
    height: auto;
    justify-content: flex-start;
    margin-top: 25px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .right-align > div:not(:last-child) {
    margin-right: 0;
  }
  .About_mainpage {
    margin: 16px;
    overflow: hidden;
    font-family: Pretendard;
  }
  .about_title {
    // width: 45vw !important;
    font-size: 24px !important;
    margin-bottom: 0 !important;
    margin-bottom: 8px !important;
  }
  .about_text {
    padding: 0 1em !important;
    font-size: 12px !important;
    // width: 45vw !important;
    // height: 80vw !important;
  }
  .about_us_link_box > div {
    font-size: 12px;
    font-weight: 500 !important;
    padding: 0 5px !important;
  }
  .about_textContainer {
    justify-content: flex-start;
    align-items: flex-start;
    height: 200vw;
    padding: 40px 8px;
  }
}

// -----------about pages
.about_container {
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.first_child {
  margin-top: 64px;
}

.about_container > div {
  flex-direction: column;
  display: flex;
  width: 50%;
  @media (max-width: 767px) {
    width: 100%;
    img {
      height: 160.02vw;
    }
  }
}

.about_us_link {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid black;
}

.about_us_link div:first-child {
  padding: 1.5em 0;
  font-weight: bold;
}
.about_us_link_box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // grid-column-gap: 10px;
  width: 100%;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    border-bottom: 1px solid #000;
  }
}
.about_us_link_box.row > div {
  padding: 1em 0;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  text-align: center;
  @media (max-width: 767px) {
    padding: 8px 0px !important;
    // padding-top: 100px;
    border-bottom: 0px;
  }
}

.about_us_link_box > div {
  flex-direction: column;
  display: flex;
  border-color: black;
  font-weight: 500 !important;
}
.about_title {
  // width: 400px;
  text-align: center;
  font-weight: bold;
  font-size: 42px;
  margin-bottom: 40px;
  color: black;
}
.about_text {
  max-width: 540px;
  padding: 0 24px;
  font-size: 18px;
  font-weight: 500;
}
.about_textContainer {
  width: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about_image {
  &:nth-child(0) {
    margin-top: 64px;
  }
}

@media screen and (max-width: 768px) {
  .About_mainpage {
    margin-top: 100px;
  }
  .h1-text {
    // font-weight: 900;
  }
  .h2-text {
    font-size: 7vw;
    line-height: 1.5;
  }
}

.footer__hr {
  background-color: #000;
}
