.digitalShowroom__title {
  position: fixed;
  top: 46px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  color: white;
  height: 40px;
  width: 100%;
  font-size: 20px;
}
