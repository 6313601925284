.BrandMainBanner_01 {
  // position: absolute;
  width: 100%;
  // left: 254px;
  // height: 100%;
  // overflow: hidden;
  // top: 866px;
  -webkit-transition: background-color 600ms linear;
  -ms-transition: background-color 600ms linear;
  transition: background-color 600ms linear;
}

.transBackground {
  -webkit-transition: background-color 600ms linear;
  -ms-transition: background-color 600ms linear;
  transition: background-color 600ms linear;
}

.wallOne {
  display: flex;
  margin: 0 10vw;
}
.second.photos {
  margin-right: 7vw;
  margin-left: 10vw;
  margin-top: 20%;
}
.brandmainbanner {
  // height: 1000px;
  overflow: hidden;
  // margin-bottom: 100px;
  position: relative;
  // z-index: 2;
}
.brandDiv {
  position: relative;
  z-index: 2;
}
.cursor {
  cursor: pointer;
}
.wallOne > div {
  display: flex;
  flex-direction: column;
  max-width: 50%;
}
.fourth.photos {
  margin-left: 7vw;
  margin-right: 7vw;
  margin-top: 20%;
}
.third.photos {
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 20%;
}
.wallOne {
  // width: 1920px;
  // height: 100%;
  // left: 254px;
  // top: 866px;
  // position: absolute;

  // .first {
  //   position: relative;
  //   left: 9.48%;
  //   right: 53.18%;
  //   // top: 3.3%;
  //   bottom: 0;
  //   width: 100%;
  // }

  // .second {
  //   position: absolute;
  //   // position: absolute;
  //   left: 64.01%;
  //   right: 11.2%;
  //   top: 7.5%;
  //   bottom: 58.13%;
  //   width: 100%;
  // }
  a.first.photos img {
    width: 100%;
  }

  // .third {
  //   position: absolute;
  //   // position: absolute;
  //   left: 55.83%;
  //   right: 7.34%;
  //   top: 19.75%;
  //   bottom: 18.42%;
  //   width: 100%;
  // }

  // .fourth {
  //   position: absolute;
  //   // position: absolute;
  //   left: 13.75%;
  //   right: 57.45%;
  //   top: 20.35%;
  //   bottom: 6.16%;
  //   width: 100%;
  // }
}
.wallOne_custom {
}
.wallOne_first {
  cursor: pointer;
  width: 100%;
  position: relative;
  margin-top: 3.8vw;
  padding-left: 9.47vw;
}
.wallOne_second {
  cursor: pointer;

  width: 100%;
  position: relative;
  display: flex;
  margin-top: -56.71vw;
  padding-right: 7.7vw;
  justify-content: flex-end;
}
.wallOne_third {
  cursor: pointer;

  width: 100%;
  position: relative;
  display: flex;
  margin-top: 8.9vw;
  padding-right: 7.7vw;
  justify-content: flex-end;
}
.wallOne_fourth {
  cursor: pointer;

  width: 100%;
  position: relative;
  display: flex;
  margin-top: -14.06vw;
  padding-left: 18.85vw;
}

.wallOne_first img {
  width: 37.5vw !important;
  aspect-ratio: 720/1270;
  height: 66.14vw;
  object-fit: cover;
}
.wallOne_second img {
  width: 25vw !important;
  aspect-ratio: 480/680;
  height: 35.41vw;
  object-fit: cover;
}
.wallOne_third img {
  width: 37.5vw !important;
  aspect-ratio: 1;
  height: 37.5vw;
  object-fit: cover;
}
.wallOne_fourth img {
  width: 28.1vw !important;
  aspect-ratio: 1;
  height: 28.1vw;
  object-fit: cover;
}

.wallOne_first video {
  width: 37.5vw !important;
  aspect-ratio: 720/1270;
  height: 66.14vw;
  object-fit: cover;
}
.wallOne_second video {
  width: 25vw !important;
  aspect-ratio: 480/680;
  height: 35.41vw;
  object-fit: cover;
}
.wallOne_third video {
  width: 37.5vw !important;
  aspect-ratio: 1;
  height: 37.5vw;
  object-fit: cover;
}
.wallOne_fourth video {
  width: 28.1vw !important;
  aspect-ratio: 1;
  height: 28.1vw;
  object-fit: cover;
}

.wallTwo_first {
  cursor: pointer;

  width: 100%;
  position: relative;
  display: flex;
  margin-top: 15.52vw;
  padding-left: 13.8vw;
  z-index: 2;
}
.wallTwo_second {
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: -57.5vw;
  padding-right: 6.71vw;
  justify-content: flex-end;
}
.wallTwo_first img {
  width: 35.41vw !important;
  aspect-ratio: 680/960;
  height: 50vw;
  object-fit: cover;
}
.wallTwo_second img {
  width: 49.47vw !important;
  aspect-ratio: 950/1500;
  height: 78.12vw;
  object-fit: cover;
}
.wallTwo_first video {
  width: 35.41vw !important;
  aspect-ratio: 680/960;
  height: 50vw;
  object-fit: cover;
}
.wallTwo_second video {
  width: 49.47vw !important;
  aspect-ratio: 950/1500;
  height: 78.12vw;
  object-fit: cover;
}

.wallThree_first {
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 7.03vw;
  padding-right: 7.86vw;
  justify-content: flex-end;
}
.wallThree_second {
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: -13.8vw;
  padding-left: 13.33vw;
}
.wallThree_third {
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  margin-top: -12.08vw;
  padding-right: 13.28vw;
  justify-content: flex-end;
}

.wallThree_first img {
  width: 53.64vw !important;
  aspect-ratio: 1030/740;
  height: 38.54vw;
  object-fit: cover;
}
.wallThree_second img {
  width: 35.41vw !important;
  aspect-ratio: 680/840;
  height: 43.75vw;
  object-fit: cover;
}
.wallThree_third img {
  width: 28.12vw !important;
  aspect-ratio: 540/860;
  height: 44.79vw;
  object-fit: cover;
}

.wallThree_first video {
  width: 53.64vw !important;
  aspect-ratio: 1030/740;
  height: 38.54vw;
  object-fit: cover;
}
.wallThree_second video {
  width: 35.41vw !important;
  aspect-ratio: 680/840;
  height: 43.75vw;
  object-fit: cover;
}
.wallThree_third video {
  width: 28.12vw !important;
  aspect-ratio: 540/860;
  height: 44.79vw;
  object-fit: cover;
}

.wallTwo_left img {
  left: 27% !important;
  z-index: 2;
  top: 9% !important;
}
.wallTwo_left {
  max-width: 40%;
}
.left_wall_three {
  max-width: 30%;
}
.right_wall_three {
  max-width: 70%;
}
.wallThree {
  display: flex;
  width: 100%;
  padding: 10vw;
}
a.eighth.photos img {
  left: 40% !important;
  z-index: 3;
  top: 25% !important;
}
a.ninth.photos img {
  width: 60%;
  margin-left: 32%;
  margin-top: 15%;
}
a.eighth.photos img {
  width: 125%;
}
.wallTwo_right {
  max-width: 60%;
}
.photos img {
  width: 100%;
}
.wallTwo {
  .fifth {
  }

  .sixth {
  }
}

.wallThree {
  .seventh {
  }

  .eighth {
  }

  .ninth {
  }
}

@media (max-width: 767px) {
  .wallTwo_first {
    cursor: pointer;

    width: 100%;
    position: relative;
    display: flex;
    margin-top: 15.52vw;
    padding-left: 8.8vw;
    z-index: 2;
  }
  .wallTwo_second {
    cursor: pointer;
    width: 100%;
    position: relative;
    display: flex;
    margin-top: -67.5vw;
    padding-right: 6.71vw;
    justify-content: flex-end;
  }
  .wallTwo_first img {
    width: 44.41vw !important;
    aspect-ratio: 680/960;
    object-fit: cover;
  }
  .wallTwo_second img {
    width: 54.47vw !important;
    aspect-ratio: 950/1500;
    object-fit: cover;
  }
  .wallTwo_first video {
    width: 44.41vw !important;
    aspect-ratio: 680/960;
    object-fit: cover;
  }
  .wallTwo_second video {
    width: 54.47vw !important;
    aspect-ratio: 950/1500;
    object-fit: cover;
  }
}
