@font-face {
  font-family: Pretendard;
  src: url("../../../font/PretendardVariable.ttf") format("truetype");
}
.login {
  height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(67, 67, 67, 1) 100%);
  display: grid;
  place-items: center;
  font-family: "Pretendard";
  font-style: normal;
  color: #ffffff;
}

.login__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  width: 240px;
}

.login__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40px;
  .login__subheading {
    font-weight: 800;
    font-size: 19px;
    line-height: 35px;
    letter-spacing: 0.25em;
    margin: 12px 0 36px;
    text-align: center;
  }
}

// Login Form--------------------------------------------------
.login__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .login__error {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #828282;
    border-radius: 12px;
    padding: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    margin-bottom: 12px;
  }
  .login__input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    label {
      font-size: 13px;
      line-height: 16px;
    }
    input {
      background: white;
      border: 1px solid black;
      outline: none;
      padding: 7px 10px;
      font-family: inherit;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      color: black;
      line-height: 18px;
      &::placeholder {
        color: #828282;
      }
    }
    &:first-child {
      margin-bottom: 20px;
    }
  }
  button {
    width: 100%;
    border: none;
    font-family: inherit;
    font-style: normal;
    font-size: 15px;
    line-height: 20px;
    padding: 7px;
    margin: 24px 0 16px;
    cursor: pointer;
    background-color: black;
    color: white;
  }
  button:hover {
    background-color: white;
    color: #1890ff !important;
  }
}
// Login Form--------------------------------------------------

.login__signup {
  font-style: normal;
  font-size: 13px;
  line-height: 20px;
  text-decoration: none;
  color: inherit;
}

.login__back {
  position: fixed;
  bottom: 80px;
  img {
    width: 44px;
    height: 44px;
    transition: opacity 0.5s;
    cursor: pointer;
  }
  img:hover {
    opacity: 0.4;
  }
}
