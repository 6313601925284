@font-face {
  font-family: "Pretendard";
  src: url("../../../font/PretendardVariable.ttf") format("truetype");
}
@font-face {
  font-family: "Pretendard";
  font-weight: bold;
  src: url("../../../font/Pretendard-Bold.otf") format("opentype");
}
body {
  font-family: "Pretendard";
  font-family: "Pretendard";
  font-weight: bold;
}
body .slider .photos {
  width: 80%;
}

.moving-gallery {
  // position: absolute;
  // width: 11615px;
  // height: 1200px;
  // // left: 323px;
  // // top: 1061px;
  // width: 100%;
  // background: #ffffff;
  position: relative;
  // width: 1920px;
  width: 100%;
  // overflow-x: hidden !important;
  // overflow-y: hidden !important;
  // height: 40%;

  &__wrapper {
    // display: flex;
    // align-items: center;
    // min-height: 200px;
    // // display: flex;
    // justify-content: start;
    // align-items: center;

    &.hidden {
      display: none;
    }
  }
  &__slide {
    // padding-right: 155px;
    // max-width: 500px;
    // display: block;
    // position: absolute;

    img {
      width: 300px;
      max-width: 100%;
      display: block;
      position: absolute;
      // padding-left: 500px;
      // padding-right: 500px;
    }
  }
}
.world-clock {
  display: inline-flex;
  align-items: center;
  position: absolute;
  top: calc(90% - 250px);
  left: calc(50% - 86px);
  z-index: 6;
  mix-blend-mode: difference;
  color: #fff;
}
.world-clock img {
  filter: invert(1);
  -webkit-animation: rotation 5s infinite linear;
  animation: rotation 5s infinite linear;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(6deg);
  }
  20% {
    transform: rotate(6deg);
  }
  30% {
    transform: rotate(12deg);
  }
  40% {
    transform: rotate(12deg);
  }
  50% {
    transform: rotate(18deg);
  }
  60% {
    transform: rotate(18deg);
  }
  70% {
    transform: rotate(24deg);
  }
  80% {
    transform: rotate(24deg);
  }
  90% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(30deg);
  }
}
.clock_content p {
  margin-bottom: 0;
}
.clock_content {
  margin-left: 25px;
  text-transform: uppercase;
  text-align: justify;
  font-family: "Pretendard";
  font-weight: bold;
}

.moving-gallery__wrapper {
  height: calc(100vh + 200px) !important;
  // width: 100% !important;
  // width: 23240px;
  // width: 11615px !important;
  width: 604vw !important;
  max-width: 11615px !important;
  position: relative;
  z-index: 0;
  overflow: visible;
  // pointer-events: auto !important;
  // @media (max-width:764) {
  //   width:5000px

  // }
}
.slider {
  backface-visibility: visible !important;
  // border: solid 1px;
}
.photos {
  pointer-events: auto !important;
  backface-visibility: visible !important;
  // width: 80%;
  // height: 70% !important;
}

.slider:hover {
  // z-index: 4;
}

.ideal_showroom_wrapper {
  position: sticky;
  top: 40vh;
  left: 100px;
  height: 180px;
  width: 100vw;
  background-color: pink;
  overflow: hidden;
  z-index: 5;
}

.ideal_showroom {
  z-index: 5;

  position: sticky;
  top: 50vh;
  left: 100px;
  font-size: 9vw;
  mix-blend-mode: difference;
  color: #fff;
  font-family: "Pretendard";
  font-weight: 700;
  line-height: 1.2;
  width: 80%;
  transform: translate(0, -50%);
  vertical-align: text-bottom;
  // line-height: 2;
}

.ideal_showroom__main {
  z-index: 5;

  position: absolute;
  top: 50vh !important;
  left: 100px;
  font-size: 9vw;
  mix-blend-mode: difference;
  color: #fff;
  font-family: "Pretendard";
  font-weight: 700;
  line-height: 1;
  width: 80%;
  transform: translate(0, -50%);
  @media (max-width: 767px) {
    top: 50vh !important;
    left: 22px;
    font-size: 12vw;
  }
}

.parallax_header {
  mix-blend-mode: difference;
}

// .moving-gallery__slide img {
//   padding-left: 10px;
//   padding-right: 10px;
// }

.brandmainbanner3 {
  overflow: hidden;
  // margin-bottom: 100px;
  position: relative;
  .banOne1 {
    position: absolute;
    left: 0% !important;
    // right: 96.8% !important;
    top: 57.17vh;
    // bottom: 0%;
    img {
      width: 38.54vw;
      aspect-ratio: 740/560;
      height: 29.16vw;
      object-fit: cover;
    }
  }

  .banOne2 {
    position: absolute;
    left: 3.64% !important;
    // right: 91.64% !important;
    top: 0vh;
    // bottom: 62.69%;

    img {
      width: 19.79vw;
      aspect-ratio: 380/570;
      height: 29.68vw;
      object-fit: cover;
    }
  }
  .banOne3 {
    position: absolute;
    left: 9.16% !important;
    // right: 87.88% !important;
    top: 23.8vh !important;
    // bottom: 21.76% !important;

    img {
      width: 31.25vw;
      aspect-ratio: 600/420;
      height: 21.87vw;
      object-fit: cover;
    }
  }
  .banOne4 {
    position: absolute;
    left: 15.23% !important;
    // right: 85.11% !important;
    top: 47.5vh;
    // bottom: 36.02%;
    img {
      width: 18.22vw;
      aspect-ratio: 350/590;
      height: 30.72vw;
      object-fit: cover;
    }
  }
  .banOne5 {
    position: absolute;
    left: 19.31% !important;
    // right: 78.42% !important;
    top: 0vh;
    // bottom: 0%;
    z-index: 2;
    img {
      width: 43.22vw;
      aspect-ratio: 830/680;
      height: 35.41vw;
      object-fit: cover;
    }
  }
  .banOne6 {
    position: absolute;
    left: 24.97% !important;
    // right: 74.39% !important;
    top: 37.31vh;
    // bottom: 42.22vh;
    img {
      width: 37.5vw;
      aspect-ratio: 720/720;
      height: 37.5vw;
      object-fit: cover;
    }
  }
  .banOne7 {
    position: absolute;
    left: 31.98% !important;
    // right: 69.11% !important;
    top: 10.19vh;
    // bottom: 0%;
    img {
      width: 27.08vw;
      aspect-ratio: 520/240;
      height: 12.5vw;
      object-fit: cover;
    }
  }
  .banOne8 {
    position: absolute;
    left: 34.23% !important;
    // right: 66.58% !important;
    top: 46.39vh;
    // bottom: 62.69%;
    z-index: 2;
    img {
      width: 33.33vw;
      aspect-ratio: 640/640;
      height: 33.33vw;
      object-fit: cover;
    }
  }
  .banOne9 {
    position: absolute;
    left: 41.84% !important;
    // right: 60.42% !important;
    top: 55.19vh;
    // bottom: 0%;
    img {
      width: 21.87vw;
      aspect-ratio: 420/420;
      height: 21.87vw;
      object-fit: cover;
    }
  }
  .banOne10 {
    position: absolute;
    left: 43.42% !important;
    // right: 56.03% !important;
    top: 7.75vh;
    // bottom: 12.96vh;
    z-index: 2;
    img {
      width: 26.04vw;
      aspect-ratio: 500/400;
      height: 20.83vw;
      object-fit: cover;
    }
  }
  .banOne11 {
    position: absolute;
    left: 48.76% !important;
    // right: 48.95% !important;
    top: 11.11vh;
    // bottom: 10.67%;
    img {
      width: 50vw;
      aspect-ratio: 960/640;
      height: 33.33vw;
      object-fit: cover;
    }
  }
  .banOne12 {
    position: absolute;
    left: 55.77% !important;
    // right: 42.41% !important;
    top: 37.31vh;
    // bottom: 0.19%;
    img {
      width: 30.2vw;
      aspect-ratio: 580/700;
      height: 36.45vw;
      object-fit: cover;
    }
  }
  .banOne13 {
    position: absolute;
    left: 60.72% !important;
    // right: 32.25% !important;
    top: 11.61vh;
    // bottom: 35.46vh;
    img {
      width: 29.16vw;
      aspect-ratio: 560/240;
      height: 12.5vw;
      object-fit: cover;
    }
  }
  .banOne14 {
    position: absolute;
    left: 64.28% !important;
    // right: 29.59% !important;
    top: 46.39vh;
    // bottom: 0%;
    img {
      width: 41.16vw;
      aspect-ratio: 800/560;
      height: 29.16vw;
      object-fit: cover;
    }
  }
  .banOne15 {
    position: absolute;
    left: 70.85% !important;
    // right: 23.81% !important;
    top: 0vh;
    // bottom: 60.83%;
    z-index: 2;
    img {
      width: 50vw;
      aspect-ratio: 960/780;
      height: 40.62vw;
      object-fit: cover;
    }
  }
  .banOne16 {
    position: absolute;
    left: 81.42% !important;
    // right: 18.92% !important;
    top: 55.17vh;
    // bottom: 9.81%;
    img {
      width: 25vw;
      aspect-ratio: 480/540;
      height: 28.12vw;
      object-fit: cover;
    }
  }
  .banOne17 {
    position: absolute;
    left: 86.61% !important;
    // right: 11.99% !important;
    top: 5.46vh;
    // bottom: 31.92%;
    img {
      width: 27.08vw;
      aspect-ratio: 520/640;
      height: 33.33vw;
      object-fit: cover;
    }
  }
  .banOne18 {
    position: absolute;
    left: 92.72% !important;
    // right: 0% !important;
    top: 23.8vh;
    // bottom: 0.17%;
    img {
      width: 50vw;
      aspect-ratio: 960/820;
      height: 42.7vw;
      object-fit: cover;
    }
  }
}

.brandmainbanner2 {
  overflow: hidden;
  // margin-bottom: 100px;
  position: relative;
  .banOne1 {
    position: absolute;
    left: 0.43% !important;
    // right: 96.8% !important;
    top: 43.33vh;
    // bottom: 0%;
    img {
      width: 18.75vw;
      aspect-ratio: 360/450;
      height: 23.43vw;
      object-fit: cover;
    }
  }

  .banOne2 {
    position: absolute;
    left: 5.47% !important;
    // right: 91.64% !important;
    top: 0vh;
    // bottom: 62.69%;

    img {
      width: 37.5vw;
      aspect-ratio: 1/1;
      height: 37.5vw;
      object-fit: cover;
    }
  }
  .banOne3 {
    position: absolute;
    left: 9.57% !important;
    // right: 87.88% !important;
    top: 46.39vh !important;
    // bottom: 21.76% !important;

    img {
      width: 26.56vw;
      aspect-ratio: 510/650;
      height: 33.85vw;
      object-fit: cover;
    }
  }
  .banOne4 {
    position: absolute;
    left: 15.57% !important;
    // right: 85.11% !important;
    top: 9.17vh;
    // bottom: 36.02%;
    img {
      width: 15.62vw;
      aspect-ratio: 300/400;
      height: 20.83vw;
      object-fit: cover;
    }
  }
  .banOne5 {
    position: absolute;
    left: 16.79% !important;
    // right: 78.42% !important;
    top: 53.83vh;
    // bottom: 0%;
    z-index: 2;
    img {
      width: 42.7vw;
      aspect-ratio: 820/460;
      height: 23.95vw;
      object-fit: cover;
    }
  }
  .banOne6 {
    position: absolute;
    left: 25.28% !important;
    // right: 74.39% !important;
    top: 46.39vh;
    // bottom: 42.22vh;
    img {
      width: 36.45vw;
      aspect-ratio: 700/700;
      height: 36.45vw;
      object-fit: cover;
    }
  }
  .banOne7 {
    position: absolute;
    left: 28.6% !important;
    // right: 69.11% !important;
    top: 10.93vh;
    // bottom: 0%;
    img {
      width: 25vw;
      aspect-ratio: 480/320;
      height: 16.66vw;
      object-fit: cover;
    }
  }
  .banOne8 {
    position: absolute;
    left: 35.47% !important;
    // right: 66.58% !important;
    top: 57.78vh;
    // bottom: 62.69%;
    z-index: 2;
    img {
      width: 22.39vw;
      aspect-ratio: 430/570;
      height: 29.68vw;
      object-fit: cover;
    }
  }
  .banOne9 {
    position: absolute;
    left: 37.04% !important;
    // right: 60.42% !important;
    top: 0vh;
    // bottom: 0%;
    img {
      width: 50vw;
      aspect-ratio: 960/760;
      height: 39.58vw;
      object-fit: cover;
    }
  }
  .banOne10 {
    position: absolute;
    left: 45.81% !important;
    // right: 56.03% !important;
    top: 28.89vh;
    // bottom: 12.96vh;
    z-index: 2;
    img {
      width: 30.2vw;
      aspect-ratio: 580/700;
      height: 36.45vw;
      object-fit: cover;
    }
  }
  .banOne11 {
    position: absolute;
    left: 54.3% !important;
    // right: 48.95% !important;
    top: 9.35vh;
    // bottom: 10.67%;
    img {
      width: 50vw;
      aspect-ratio: 960/760;
      height: 39.58vw;
      object-fit: cover;
    }
  }
  .banOne12 {
    position: absolute;
    left: 62.54% !important;
    // right: 42.41% !important;
    top: 56.3vh;
    // bottom: 0.19%;
    img {
      width: 15.1vw;
      aspect-ratio: 290/440;
      height: 22.91vw;
      object-fit: cover;
    }
  }
  .banOne13 {
    position: absolute;
    left: 67.63% !important;
    // right: 32.25% !important;
    top: 18.75vh;
    // bottom: 35.46vh;
    img {
      width: 40.62vw;
      aspect-ratio: 780/360;
      height: 18.75vw;
      object-fit: cover;
    }
  }
  .banOne14 {
    position: absolute;
    left: 75.04% !important;
    // right: 29.59% !important;
    top: 45vh;
    // bottom: 0%;
    img {
      width: 21.87vw;
      aspect-ratio: 420/420;
      height: 21.87vw;
      object-fit: cover;
    }
  }
  .banOne15 {
    position: absolute;
    left: 81.48% !important;
    // right: 23.81% !important;
    top: 46.2vh;
    // bottom: 60.83%;
    z-index: 2;
    img {
      width: 50vw;
      aspect-ratio: 960/650;
      height: 33.85vw;
      object-fit: cover;
    }
  }
  .banOne16 {
    position: absolute;
    left: 88.6% !important;
    // right: 18.92% !important;
    top: 0vh;
    // bottom: 9.81%;
    img {
      width: 32.29vw;
      aspect-ratio: 620/700;
      height: 36.45vw;
      object-fit: cover;
    }
  }
  .banOne17 {
    position: absolute;
    left: 93.83% !important;
    // right: 11.99% !important;
    top: 53.8vh;
    // bottom: 31.92%;
    img {
      width: 40.62vw;
      aspect-ratio: 780/360;
      height: 18.75vw;
      object-fit: cover;
    }
  }
  .banOne18 {
    position: absolute;
    left: 91.04% !important;
    // right: 0% !important;
    top: 28.67vh;
    // bottom: 0.17%;
    img {
      width: 62.5vw;
      aspect-ratio: 1200/860;
      height: 44.79vw;
      object-fit: cover;
    }
  }
}

.brandmainbanner1 {
  overflow: hidden;
  // margin-bottom: 100px;
  position: relative;
  .banOne1 {
    position: absolute;
    left: 0% !important;
    // right: 96.8% !important;
    top: 66.11vh;
    // bottom: 0%;
    img {
      width: 21.87vw;
      aspect-ratio: 1;
      height: 21.87vw;
      object-fit: cover;
    }
  }

  .banOne2 {
    position: absolute;
    left: 1.84% !important;
    // right: 91.64% !important;
    // top: 0%;
    bottom: 62.69%;

    img {
      width: 44.79vw;
      aspect-ratio: 860/450;
      height: 23.43vw;
      object-fit: cover;
    }
  }
  .banOne3 {
    position: absolute;
    left: 7.85% !important;
    // right: 87.88% !important;
    top: 57.78vh !important;
    // bottom: 21.76% !important;

    img {
      width: 29.16vw;
      aspect-ratio: 564/246;
      height: 12.81vw;
      object-fit: cover;
    }
  }
  .banOne4 {
    position: absolute;
    left: 11.45% !important;
    // right: 85.11% !important;
    top: 13.52vh;
    // bottom: 36.02%;
    img {
      width: 23.64vw;
      aspect-ratio: 454/606;
      height: 31.56vw;
      object-fit: cover;
    }
  }
  .banOne5 {
    position: absolute;
    left: 17.73% !important;
    // right: 78.42% !important;
    top: 46.39vh;
    // bottom: 0%;
    z-index: 2;
    img {
      width: 26.56vw;
      aspect-ratio: 510/650;
      height: 33.85vw;
      object-fit: cover;
    }
  }
  .banOne6 {
    position: absolute;
    left: 20.15% !important;
    // right: 74.39% !important;
    // top: 0%;
    bottom: 42.22vh;
    img {
      width: 37.5vw;
      aspect-ratio: 720/720;
      height: 37.5vw;
      object-fit: cover;
    }
  }
  .banOne7 {
    position: absolute;
    left: 27.64% !important;
    // right: 69.11% !important;
    top: 56.11vh;
    // bottom: 0%;
    img {
      width: 25vw;
      aspect-ratio: 480/480;
      height: 25vw;
      object-fit: cover;
    }
  }
  .banOne8 {
    position: absolute;
    left: 29.77% !important;
    // right: 66.58% !important;
    top: 10.93vh;
    // bottom: 62.69%;
    img {
      width: 25vw;
      aspect-ratio: 480/320;
      height: 16.66vw;
      object-fit: cover;
    }
  }
  .banOne9 {
    position: absolute;
    left: 34.23% !important;
    // right: 60.42% !important;
    top: 46.39vh;
    // bottom: 0%;
    img {
      width: 36.66vw;
      aspect-ratio: 704/644;
      height: 33.54vw;
      object-fit: cover;
    }
  }
  .banOne10 {
    position: absolute;
    left: 41.33% !important;
    // right: 56.03% !important;
    // top: 0%;
    bottom: 63.25vh;
    z-index: 2;
    img {
      width: 18.22vw;
      aspect-ratio: 350/450;
      height: 23.43vw;
      object-fit: cover;
    }
  }
  .banOne11 {
    position: absolute;
    left: 42.65% !important;
    // right: 48.95% !important;
    top: 27.58vh;
    // bottom: 10.67%;
    img {
      width: 57.29vw;
      aspect-ratio: 1100/740;
      height: 38.54vw;
      object-fit: cover;
    }
  }
  .banOne12 {
    position: absolute;
    left: 53.2% !important;
    // right: 42.41% !important;
    top: 41.67vh;
    // bottom: 0.19%;
    img {
      width: 30.2vw;
      aspect-ratio: 580/700;
      height: 36.45vw;
      object-fit: cover;
    }
  }
  .banOne13 {
    position: absolute;
    left: 60.44% !important;
    // right: 32.25% !important;
    // top: 0%;
    bottom: 35.46vh;
    img {
      width: 50.31vw;
      aspect-ratio: 966/774;
      height: 40.31vw;
      object-fit: cover;
    }
  }
  .banOne14 {
    position: absolute;
    left: 66.56% !important;
    // right: 29.59% !important;
    top: 46.39vh;
    // bottom: 0%;
    img {
      width: 26.56vw;
      aspect-ratio: 510/650;
      height: 33.85vw;
      object-fit: cover;
    }
  }
  .banOne15 {
    position: absolute;
    left: 71.91% !important;
    // right: 23.81% !important;
    top: 18.7vh;
    // bottom: 60.83%;
    img {
      width: 29.37vw;
      aspect-ratio: 564/246;
      height: 12.81vw;
      object-fit: cover;
    }
  }
  .banOne16 {
    position: absolute;
    left: 77.88% !important;
    // right: 18.92% !important;
    top: 56.3vh;
    // bottom: 9.81%;
    img {
      width: 21.87vw;
      aspect-ratio: 420/420;
      height: 21.87vw;
      object-fit: cover;
    }
  }
  .banOne17 {
    position: absolute;
    left: 82.02% !important;
    // right: 11.99% !important;
    top: 14.42vh;
    // bottom: 31.92%;
    img {
      width: 41.14vw;
      aspect-ratio: 790/650;
      height: 33.85vw;
      object-fit: cover;
    }
  }
  .banOne18 {
    position: absolute;
    left: 91.04% !important;
    // right: 0% !important;
    top: 28.67vh;
    // bottom: 0.17%;
    img {
      width: 62.5vw;
      aspect-ratio: 1200/860;
      height: 44.79vw;
      object-fit: cover;
    }
  }
}

.banOne1 {
  position: absolute;
  left: 0%;
  // right: 96.8% !important;
  top: 66.11%;
  bottom: 0%;
  height: 406px;
  width: 422px;
}
.banOne2 {
  position: absolute;
  left: 1.84%;
  // right: 91.64% !important;
  top: 0%;
  bottom: 62.69%;
  height: 447px;
  width: 860px;
}
.banOne3 {
  position: absolute;
  left: 7.85% !important;
  // right: 87.88% !important;
  top: 57.78% !important;
  bottom: 21.76% !important;
  width: 563px !important;
  height: 245px !important;
}
.banOne4 {
  position: absolute;
  left: 11.45% !important;
  // right: 85.11% !important;
  top: 13.52%;
  bottom: 36.02%;
}
.banOne5 {
  position: absolute;
  left: 17.73% !important;
  // right: 78.42% !important;
  top: 46.39%;
  bottom: 0%;
}
.banOne6 {
  position: absolute;
  left: 20.15% !important;
  // right: 74.39% !important;
  top: 0%;
  bottom: 42.22%;
}
.wallTwo > div img {
  width: 100%;
}
// .wallTwo > div {
//   max-width: 50%;
// }
.wallTwo {
  margin: 10vw;
  display: flex;
}
.banOne7 {
  position: absolute;
  left: 27.64% !important;
  // right: 69.11% !important;
  top: 66.11%;
  bottom: 0%;
}
.banOne8 {
  position: absolute;
  left: 29.77% !important;
  // right: 66.58% !important;
  top: 10.93%;
  bottom: 62.69%;
}
.banOne9 {
  position: absolute;
  left: 34.23% !important;
  // right: 60.42% !important;
  top: 46.39%;
  bottom: 0%;
}
.banOne10 {
  position: absolute;
  left: 41.33% !important;
  // right: 56.03% !important;
  top: 0%;
  bottom: 63.25%;
}
.banOne11 {
  position: absolute;
  left: 42.65% !important;
  // right: 48.95% !important;
  top: 27.58%;
  bottom: 10.67%;
}
.banOne12 {
  position: absolute;
  left: 53.2% !important;
  // right: 42.41% !important;
  top: 41.67%;
  bottom: 0.19%;
}
.banOne13 {
  position: absolute;
  left: 60.44% !important;
  // right: 32.25% !important;
  top: 0%;
  bottom: 35.46%;
}
.banOne14 {
  position: absolute;
  left: 66.56% !important;
  // right: 29.59% !important;
  top: 46.39%;
  bottom: 0%;
}
.banOne15 {
  position: absolute;
  left: 71.91% !important;
  // right: 23.81% !important;
  top: 18.7%;
  bottom: 60.83%;
}
.banOne16 {
  position: absolute;
  left: 77.88% !important;
  // right: 18.92% !important;
  top: 56.3%;
  bottom: 9.81%;
}
.banOne17 {
  position: absolute;
  left: 82.02% !important;
  // right: 11.99% !important;
  top: 14.42%;
  bottom: 31.92%;
}
.banOne18 {
  position: absolute;
  left: 91.04% !important;
  // right: 0% !important;
  top: 28.67%;
  bottom: 0.17%;
}

.banTwo1 {
  position: absolute;
  left: 0.43%;
  // right: 96.87%;
  top: 43.33%;
  bottom: 18.98%;
}
.banTwo2 {
  position: absolute;
  left: 5.47%;
  // right: 89.07%;
  top: 0%;
  bottom: 42.22%;
}
.banTwo3 {
  position: absolute;
  left: 9.57%;
  // right: 86.58%;
  top: 46.39%;
  bottom: 0%;
}
.banTwo4 {
  position: absolute;
  left: 15.57%;
  // right: 81.98%;
  top: 9.17%;
  bottom: 58.33%;
}
.banTwo5 {
  position: absolute;
  left: 16.79%;
  // right: 77.02%;
  top: 53.83%;
  bottom: 7.42%;
}
.banTwo6 {
  position: absolute;
  left: 25.28%;
  // right: 69.38%;
  top: 46.39%;
  bottom: 0%;
}
.banTwo7 {
  position: absolute;
  left: 28.6%;
  // right: 67.75%;
  top: 10.93%;
  bottom: 62.69%;
}
.banTwo8 {
  position: absolute;
  left: 35.47%;
  // right: 61.28%;
  top: 57.78%;
  bottom: 0%;
}
.banTwo9 {
  position: absolute;
  left: 37.04%;
  // right: 55.64%;
  top: 0%;
  bottom: 36.67%;
}
.banTwo10 {
  position: absolute;
  left: 45.81%;
  // right: 49.8%;
  top: 28.89%;
  bottom: 12.96%;
}
.banTwo11 {
  position: absolute;
  left: 54.3%;
  // right: 38.39%;
  top: 9.35%;
  bottom: 26.11%;
}
.banTwo12 {
  position: absolute;
  left: 62.54%;
  // right: 35.3%;
  top: 56.3%;
  bottom: 7.04%;
}
.banTwo13 {
  position: absolute;
  left: 67.63%;
  // right: 26.41%;
  top: 18.75%;
  bottom: 50.75%;
}
.banTwo14 {
  position: absolute;
  left: 75.04%;
  // right: 21.76%;
  top: 45%;
  bottom: 21.11%;
}
.banTwo15 {
  position: absolute;
  left: 81.48%;
  // right: 11.18%;
  top: 46.2%;
  bottom: 0.19%;
}
.banTwo16 {
  position: absolute;
  left: 86.45%;
  // right: 8.89%;
  top: 0%;
  bottom: 42.22%;
}
.banTwo17 {
  position: absolute;
  left: 93.83%;
  // right: 0.74%;
  top: 53.8%;
  bottom: 12.96%;
}

.banThree1 {
  position: absolute;
  left: 0%;
  // right: 94.38%;
  top: 57.17%;
  bottom: -4.02%;
}
.banThree2 {
  position: absolute;
  left: 3.64%;
  right: 93.45%;
  top: 0%;
  bottom: 52.5%;
}
.banThree3 {
  position: absolute;
  left: 9.16%;
  // right: 86.33%;
  top: 23.8%;
  bottom: 40.74%;
}
.banThree4 {
  position: absolute;
  left: 15.23%;
  // right: 82.05%;
  top: 47.5%;
  bottom: 3.33%;
}
.banThree5 {
  position: absolute;
  left: 19.31%;
  // right: 74.37%;
  top: 0%;
  bottom: 42.83%;
}
.banThree6 {
  position: absolute;
  left: 24.97%;
  // right: 69.57%;
  top: 37.31%;
  bottom: 0%;
}
.banThree7 {
  position: absolute;
  left: 31.98%;
  // right: 64.11%;
  top: 10.19%;
  bottom: 69.63%;
}
.banThree8 {
  position: absolute;
  left: 34.23%;
  // right: 60.42%;
  top: 46.39%;
  bottom: 0%;
}
.banThree9 {
  position: absolute;
  left: 41.84%;
  // right: 54.91%;
  top: 55.19%;
  bottom: 10.93%;
}
.banThree10 {
  position: absolute;
  left: 43.42%;
  // right: 52.8%;
  top: 7.75%;
  bottom: 57.75%;
}
.banThree11 {
  position: absolute;
  left: 48.76%;
  // right: 43.97%;
  top: 11.11%;
  bottom: 35.46%;
}
.banThree12 {
  position: absolute;
  left: 54.77%;
  // right: 40.84%;
  top: 37.31%;
  bottom: 4.54%;
}
.banThree13 {
  position: absolute;
  left: 60.72%;
  // right: 35.01%;
  top: 13.61%;
  bottom: 65.93%;
}
.banThree14 {
  position: absolute;
  left: 64.28%;
  // right: 29.59%;
  top: 46.39%;
  bottom: 6.76%;
}
.banThree15 {
  position: absolute;
  left: 70.85%;
  // right: 21.83%;
  top: 0%;
  bottom: 35.46%;
}
.banThree16 {
  position: absolute;
  left: 80.42%;
  // right: 15.97%;
  top: 55.17%;
  bottom: 0.17%;
}
.banThree17 {
  position: absolute;
  left: 86.61%;
  // right: 9.54%;
  top: 5.46%;
  bottom: 40.93%;
}
.banThree18 {
  position: absolute;
  left: 86.61%;
  // right: 9.54%;
  top: 5.46%;
  bottom: 40.93%;
}

@media (max-width: 767px) {
  .ideal_showroom {
    top: 50vh !important;
    left: 22px;
    font-size: 12vw;
  }
  .backButton {
    right: -18px;
  }
  .brandButton {
    left: -10px;
  }
  .digitalButton {
    bottom: 8%;
    left: -155px;
  }
  .aboutButton {
    top: 12%;
    right: -3%;
  }
  .calendarButton {
    bottom: 9%;
    right: -6.6%;
  }
  .world-clock {
    left: calc(50% - 71px);
  }
  .world-clock img {
    width: 60px;
  }
  .clock_content {
    font-size: 12px;
  }
}
