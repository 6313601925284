@font-face {
  font-family: "Pretendard";
  font-weight: bold;
  src: url("../../font/Pretendard-Bold.otf") format("opentype");
}
body {
  font-family: "Pretendard";
  font-weight: bold;
}
.slider-container {
  z-index: 500;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
// #brandinfo1----------------------------------------------
.Brandinfo {
  position: relative;
  width: 100%;
  // height: 12500px;
  // height: 15293px;
  left: 0px;
  top: 0px;
  overflow: hidden;

  // background: linear-gradient(
  //   180deg,
  //   #dae0c8 0%,
  //   #dae0c8 86.98%,
  //   #fff0db 89.06%,
  //   #fff0db 100%
  // );
}

#ideal {
  position: absolute;
  left: 45%;
  right: 90.25%;
  top: 20px;
  bottom: 76.49%;
  width: 80px;
  height: 30px;
  line-height: 10px;
  font-size: 30px;
  color: black;
  border: none;
  font-family: "Pretendard";
  font-weight: bold;
}

.Brandinfo #upper_text {
  position: absolute;
  width: 920px !important;
  height: 300px;
  left: 125px;
  top: 170px;
  z-index: 2;
  mix-blend-mode: difference;
  /* headline1 */

  font-family: "Pretendard";
  font-weight: bold;
  font-style: normal;
  font-weight: 500;
  font-size: 120px;
  line-height: 145px;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: slategrey;
  color: white;
  position: fixed;
  // border: 2px solid #000000;
}

#pic_1 {
  position: relative;
  // width: 100%;
  // left: 53.49%;
  // right: 0%;
  // top: 0%;
  // width: 46.5% !important;
  // width: 46.35%;
  // height: 1350px;
  // aspect-ratio: 890/1350;
  z-index: 1;
  display: flex;
  justify-content: flex-end;
}

#pic_2 {
  position: relative;
  display: flex;
  margin-top: -7vw;
  // width: 75% !important;
}

#pic_3 {
  position: relative;
  display: flex;
  margin-top: 11.35vw;
  left: 11.25vw;
  // width: 55% !important;
}
#pic_4 {
  position: relative;
  display: flex;
  margin-top: 11.77vw;
  right: 4.47vw;
  justify-content: flex-end;
  // width: 70% !important;
}

#pic_7 {
  position: relative;
  display: flex;
  margin-top: -6.09vw;
  left: 11.25vh;
  // width: 66.7% !important;
}

#pic_8 {
  position: relative;
  display: flex;
  margin-top: 13.85vw;
  justify-content: flex-end;
  // width: 42.3% !important;
}

#pic_6 {
  position: relative;
  display: flex;
  margin-top: 14.58vw;
  // width: 100% !important;
}

#pic_9 {
  position: relative;
  display: flex;
  margin-top: 13.64vw;
  left: 11.25vw;
  // width: 50% !important;
}

#pic_10 {
  position: relative;
  display: flex;
  margin-top: 15.88vw;
  left: 37.44vw;
  // width: 45% !important;
}

#pic_11 {
  position: relative;
  display: flex;
  margin-top: 12.81vw;

  // width: 65% !important;
}

#pic_12 {
  position: relative;
  display: flex;
  margin-top: -5.41vw;
  right: 7.55vw;
  justify-content: flex-end;
  // width: 63% !important;
}

#pic_13 {
  position: relative;
  display: flex;
  margin-top: 11.35vw;
  left: 14.32vw;
  // width: 30% !important;
}
// #pic_14 {
//   position: relative;
//   left: 13.8%;
//   right: 50.47%;
//   top: 89.49%;
//   // width: 34% !important;
//   z-index: 1;
// }

// #pic_15 {
//   position: relative;
//   left: 43.8%;
//   right: 6.51%;
//   top: 88.7%;
//   // width: 47% !important;
// }

.brand-img1-1 {
  // width: 890px;
  width: 46.35vw !important;
  // height: 1350px;
  aspect-ratio: 890/1350;
  height: 70.31vw;
  object-fit: cover;
}

.brand-img1-2 {
  // height: 1100px;
  // width: 1480px;
  width: 77.08vw !important;
  aspect-ratio: 1480/1100;
  height: 57.29vw;
  object-fit: cover;
}

.brand-img1-3 {
  width: 47.39vw !important;
  aspect-ratio: 910/1120;
  height: 58.33vw;
  object-fit: cover;
}
.brand-img1-4 {
  width: 68.22vw !important;
  aspect-ratio: 1310/940;
  height: 48.95vw;
  object-fit: cover;
}

.brand-img1-7 {
  width: 72.91% !important;
  aspect-ratio: 1;
  height: 72.91vw;
  object-fit: cover;
}

.brand-img1-8 {
  width: 42.18% !important;
  aspect-ratio: 810/1000;
  height: 52.08vw;
  object-fit: cover;
}

.brand-img1-6 {
  width: 100% !important;
  aspect-ratio: 1940/1440;
  height: 75vw;
  object-fit: cover;
}

.brand-img1-9 {
  width: 47.39% !important;
  aspect-ratio: 910/1120;
  height: 58.33vw;
  object-fit: cover;
}

.brand-img1-10 {
  width: 47.39% !important;
  aspect-ratio: 910/1120;
  height: 58.33vw;
  object-fit: cover;
}

.brand-img1-11 {
  width: 70.31% !important;
  aspect-ratio: 1350/1010;
  height: 52.6vw;
  object-fit: cover;
}

.brand-img1-12 {
  width: 57.29% !important;
  aspect-ratio: 1100/860;
  height: 44.79vw;
  object-fit: cover;
}

.brand-img1-13 {
  width: 30.72% !important;
  aspect-ratio: 590/980;
  height: 51.04vw;
  object-fit: cover;
}
// .brand-img-14 {
// }

// .brand-img-15 {
// }

#bottom_text {
  cursor: pointer;
  position: absolute;
  width: 920px;
  height: 300px;
  left: 125px;
  top: 11278px;
  z-index: 2;

  /* headline1 */
  font-family: "Pretendard";
  font-weight: bold;
  font-style: normal;
  font-weight: bold;
  font-size: 120px;
  line-height: 135px;
  mix-blend-mode: difference;
  color: #fff;
  border: none;
}

#BrandInfo1_back {
  transform: rotatez(90deg);
  z-index: 2;
  position: relative;
  left: 94%;
  top: 55px;
  width: 65px;
  height: 29px;
  cursor: pointer;

  /* Subtitle2 */

  font-family: "Pretendard";
  font-weight: bold;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
}

// -----cursors
.Brandinfo .photos {
  cursor: pointer;
}

// -----slick-lighbox
.slick-track {
  display: flex;
  width: min-content;
  overflow-y: hidden;
}
.slick-slider {
  background-color: #dae0c8;
}
.slick-slide {
  height: 100vh;
}
.slick-list {
  overflow: hidden;
}
button.slick-arrow {
  position: absolute;
}
.slick-slide img,
.slick-slide video {
  height: 100vh;
  object-fit: contain;
  width: 100%;
}
.cursor_overlay {
  height: 100vh;
  // background: #ff0000ad;
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  // pointer-events: none;
  mix-blend-mode: difference;
  // cursor: none;
}
.cursor_overlay > div {
  flex: 1;
}
.cursor_overlay > img {
  color: #fff;
  filter: invert(1);
}

.main-slider > .slick-arrow {
  display: none !important;
}
.spot-one {
  position: absolute;
  border-color: slateblue;
  filter: invert(1);
  display: none;
}
.spot-two {
  position: absolute;
  border-color: slateblue;
  filter: invert(1);
  display: none;
}
.spot-three {
  position: absolute;
  border-color: slateblue;
  filter: invert(1);
  display: none;
}
.right-side:hover .spot-three {
  display: block !important;
}
.center-side:hover .spot-two {
  display: block !important;
}
.left-side:hover .spot-one {
  display: block !important;
}
.nextBanner {
  position: relative;
}
@media (max-width: 767px) {
  .Brandinfo {
    // height: 5000px;
  }
}

.container_photos {
  height: 88%;
}
