.calendar__header {
  height: 64px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid black;
  position: fixed;
}

.calendar__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  min-height: 100vh;
}

.calendar__left {
  padding: 70px 0;
  background: #000000;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .calendar__title {
    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 20px;
      color: white;
      &:nth-child(1) {
        margin-top: 64px;
      }
      &:nth-child(2) {
        margin-bottom: 32px;
      }
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 30px;
      margin-bottom: 0px;

      &:last-child {
        margin-bottom: 76px;
      }
    }
  }
  .calendar__options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    padding: 0px 8px;
    .calendar__option-title {
      font-style: normal;
      font-size: 12px;
      line-height: 30px;
      color: white;
    }
    h4 {
      color: white;
      font-weight: bold !important;
    }
    .calendar__option-options {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 30px;
    }
  }
}

.calendar__right {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .calendar__login-title {
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0.09em;
    margin-bottom: 50px;
  }
  button {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.1em;
    display: block;
    margin: 0 auto;
    padding: 10px 80px;
    border: 1px solid #606060;
    border-radius: 25px;
    background: #ffffff;
    &:not(:last-child) {
      margin-bottom: 22px;
    }
    cursor: pointer;
  }
}

.calendar__wrapper {
  width: 100%;
  height: 600px;
}

@media only screen and (min-width: 1024px) {
  .calendar__container {
    flex-direction: row;
  }
  .calendar__left {
    width: 100%;
    height: 100vh;
    padding: 0;
  }
  .calendar__right {
    width: 100%;
    height: 100vh;
  }
}

@media only screen and (min-width: 1400px) {
  .calendar__left {
    .calendar__title {
      h1 {
        font-size: 26px;
        line-height: 26px;
        &:nth-child(2) {
          margin-bottom: 32px;
        }
      }
      p {
        font-size: 14px;
        line-height: 32px;
        font-weight: 400;
        &:nth-child(2) {
          margin: 37px 0 49px;
        }
        &:not(:last-child) {
          margin-bottom: 0px;
        }
        &:last-child {
          margin-bottom: 96px;
        }
      }
    }
    .calendar__options {
      .calendar__option-title {
        font-size: 15px;
        line-height: 35px;
      }
      .calendar__option-options {
        font-size: 15px;
        line-height: 35px;
      }
    }
  }

  .calendar__right {
    .calendar__login-title {
      h2 {
        font-weight: 700 !important;
        font-size: 24px;
        line-height: 40px;
        letter-spacing: 0.16em;
        margin-bottom: 50px;
      }
    }
    button {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.1em;
      padding: 10px 88px;
      border-radius: 30px;
      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }
  }
}

// @media only screen and (min-width: 1920px) {
//     .calendar__left-container {
//         max-width: 95%;
//         margin: 0 auto;
//     }
//     .calendar__right-container {
//         max-width: 95%;
//         margin: 0 auto;
//     }
// }

// Calendly clasees
// .calendly-inline-widget {
// margin: 50% 0;
// }
